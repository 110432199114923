import { defineStore } from 'pinia';
import { SaleService } from '@/services/SaleService';
import {
  DataTotalSaleReturn,
  DataGrowth,
  SaleChannelInformation,
} from '@/utils/types/sale';
import { FilterValue } from '@/utils/types/dropdown';

export const useSaleStore = defineStore('sales', {
  state: () => {
    return {
      salesChannel: [] as string[],
      datasetTotalSaleReturn: [] as DataTotalSaleReturn[], 
      datasetYearlyGrowth: [] as DataGrowth[],
      selectedSaleChannel: '',
      salesChannelInfo: [] as number[],
      selectedFilterValue: {} as FilterValue,
      homeTotalSale: 0,
      homeTotalClaim: 0,
    };
  },
  actions: {
    async getSalesChannel(payload: any = {}) {
      const response = await SaleService.getSalesChannel(payload);
      if (response.status === 200) {
        this.salesChannel = response.data;
      }
      return response;
    },
    async getSalesChannelHome(payload: any = {}) {
      const response = await SaleService.getSalesChannelHome(payload);
      if (response.status === 200) {
        this.salesChannel = response.data;
      }
      return response;
    },
    async getSalesReturn(payload: any = {}) {
      const response = await SaleService.getSalesReturn(payload);
      if (response.status === 200) {
        if (response.data) {
          this.datasetTotalSaleReturn = response.data;
        } else {
          this.datasetTotalSaleReturn = [];
        }
      }
      return response;
    },
    async getYearlyGrowth(payload: any = {}) {
      const response = await SaleService.getYearlyGrowth(payload);
      if (response.status === 200) {
        this.datasetYearlyGrowth = response.data;
      }
      return response;
    },
    async setSaleChannel(channel: string = '') {
      this.selectedSaleChannel = channel;
    },
    async getSalesChannelInfo(payload: {
      companyName?: string | string[],
      type: string | string[],
      country: string | string[],
    }) {
      this.selectedFilterValue = payload;
      const response = await SaleService.getSalesChannelInfo(payload);
      if (response.status === 200) {
        this.salesChannelInfo = response.data;
        sessionStorage.setItem('salesChannelInfo', JSON.stringify(this.salesChannelInfo));
      }
      return response;
    },
    setSalesChannelInfo(salesChannelInfo: number[]) {
      this.salesChannelInfo = salesChannelInfo;
    },
    async getSalesOverview() {
      const response = await SaleService.getSalesOverview();
      if (response.status === 200) {
        this.homeTotalSale = response.data.totalSale;
        this.homeTotalClaim = response.data.totalClaim;
      }
      return response;
    },
    setSelectedFilterValue(filterValues: FilterValue) {
      this.selectedFilterValue = filterValues;
    },
    async getSalesGrowth(period: string) {
      const response = await SaleService.getSalesGrowth(period);
      if (response.status === 200) {
        if (period === 'daily') {
          this.datasetTotalSaleReturn = response.data;
        } else {
          this.datasetYearlyGrowth = response.data;
        }
      }
      return response;
    },
  },
  getters: {
    getSelectedSaleChannel(stateData): string {
      return stateData.selectedSaleChannel;
    },
    getDatasetTotalReturn(stateData): any {
      let datasets = {} as any;
      stateData.datasetYearlyGrowth.forEach((item) => {
        const dateString = item.date.split('-');
        const yearly = dateString[0];
        const monthly = Number(dateString[1]).toString();
        if (Object.keys(datasets).includes(yearly)) {
          datasets[dateString[0]][monthly] = Number(item.totalClaim);
        } else {
          datasets = {
            ...datasets,
            [yearly]: {
              [monthly]: Number(item.totalClaim),
            },
          };
        }
      });
      return datasets;
    },
    getDatasetTotalSale(stateData): any {
      let datasets = {} as any;
      stateData.datasetYearlyGrowth.forEach((item) => {
        const dateString = item.date.split('-');
        const yearly = dateString[0];
        const monthly = Number(dateString[1]).toString();
        if (Object.keys(datasets).includes(yearly)) {
          datasets[dateString[0]][monthly] = Number(item.totalClaim);
        } else {
          datasets = {
            ...datasets,
            [yearly]: {
              [monthly]: Number(item.totalClaim),
            },
          };
        }
      });
      return datasets;
    },
    getHomeReturnRate(stateData): number {
      if (stateData.homeTotalClaim === 0 || stateData.homeTotalSale === 0) {
        return 0;
      }
      return (stateData.homeTotalClaim/stateData.homeTotalSale) * 100;
    },
  },
});