import { apiClient } from '@/services';
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { useCalendarStore } from '@/stores/useCalendarStore';
import { useSaleStore } from '@/stores/useSaleStore';
import {
  ProductPayload,
  ProductDetailsPayload,
} from '@/utils/types/products';

export const ProductService = {
  getTopClaimProducts () {
    const calendarStore = useCalendarStore();
    const saleStore = useSaleStore();
    const { salesChannelInfo } = storeToRefs(saleStore);
    const payload = {
      dateFrom: dayjs(calendarStore.calendarDateRange.start).format('YYYY-MM-DD'),
      dateTo: dayjs(calendarStore.calendarDateRange.end).format('YYYY-MM-DD'),
      salesChannelId: salesChannelInfo.value,
    };
    return apiClient.get('/products/top-claims', {
      params: payload,
    });
  },
  getAllProducts (payload: ProductPayload) {
    const calendarStore = useCalendarStore();
    const saleStore = useSaleStore();
    const { salesChannelInfo } = storeToRefs(saleStore);
    const newPayload = {
      ...payload,
      dateFrom: dayjs(calendarStore.calendarDateRange.start).format('YYYY-MM-DD'),
      dateTo: dayjs(calendarStore.calendarDateRange.end).format('YYYY-MM-DD'),
      salesChannelId: salesChannelInfo.value,
    };
    return apiClient.get('/products/', {
      params: newPayload,
    });
  },
  getProductDetails (payload: ProductDetailsPayload) {
    return apiClient.get('/products/details', {
      params: payload,
    });
  },
};