import { defineStore } from 'pinia';
import { ProductService } from '@/services/ProductService';
import {
  ProductTopClaimResponse,
  ProductClaimResponse,
  ProductPayload,
  ProductDetailsPayload,
} from '@/utils/types/products';

export const useProductStore = defineStore('product', {
  state: () => {
    return {
      topClaimProducts: [] as ProductTopClaimResponse[],
      allProducts: [] as ProductClaimResponse[],
      productDetails: {} as any,

      selectedProductDetailsColors: [] as string[],
    };
  },
  actions: {
    async getAllProducts (payload: ProductPayload) {
      const response = await ProductService.getAllProducts(payload);
      if (response.status === 200) {
        this.allProducts = response.data;
      }
      return response;
    },
    async getTopClaimProducts () {
      const response = await ProductService.getTopClaimProducts();
      if (response.status === 200) {
        this.topClaimProducts = response.data;
      }
      return response;
    },
    async getProductDetails (payload: ProductDetailsPayload) {
      const response = await ProductService.getProductDetails(payload);
      if (response.status === 200) {
        this.productDetails = response.data;
      }
      return response;
    },
    setProductDetailsColors(data: string[]) {
      this.selectedProductDetailsColors = data;
      sessionStorage.setItem('selectedProductDetailsColors', JSON.stringify(data));
    },
  },
  getters: {
    getHomeMostReturnRate(stateData): number {
      if (stateData.topClaimProducts.length === 0
        || !stateData.topClaimProducts[0].totalReturn
        || !stateData.topClaimProducts[0].totalSale) {
        return 0;
      }

      const totalClaim = Number(stateData.topClaimProducts[0].totalReturn);
      const totalSale = Number(stateData.topClaimProducts[0].totalSale);

      return (totalClaim / totalSale) * 100;
    },
  },
});