import { defineStore } from 'pinia';
import {
  CalendarRange,
} from '@/utils/types/calendar';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

export const useCalendarStore = defineStore('filterCalendar', {
  state: () => {
    return {
      calendarDateRange: {
        start: null,
        end: null,
      } as CalendarRange,
      calendarHomeDateRange: {
        start: null,
        end: null,
      } as CalendarRange,
      currentLocale: 'de',
      singleDate: new Date(),
    };
  },
  actions: {
    setInitDate () {
      const startDate = new Date('2022-01-01');
      this.calendarDateRange.start = startDate;
      const endDate = new Date('2022-12-31');
      this.calendarDateRange.end = endDate;
      sessionStorage.setItem('calendarDateRange', JSON.stringify({
        start: startDate,
        end: endDate,
      }));
    },
    setDate (data: CalendarRange) {
      this.calendarDateRange = {
        start: data.start,
        end: data.end,
      };
      sessionStorage.setItem('calendarDateRange', JSON.stringify(data));
    },
    setSingleDate (data: Date) {
      this.singleDate = data;
      sessionStorage.setItem('calendarSingleDate', data.getTime().toString());
    },
  },
  getters: {
    getFormattedDate(stateData) {
      const startDate = stateData.calendarDateRange.start
        ? dayjs(stateData.calendarDateRange.start).locale(stateData.currentLocale).format('ll')
        : '';
      const endDate = stateData.calendarDateRange.end
        ? dayjs(stateData.calendarDateRange.end).locale(stateData.currentLocale).format('ll')
        : '';

      return `${startDate} - ${endDate}`;
    },
    getHomeFormattedDate(stateData) {
      const startDate = stateData.calendarDateRange.start
        ? dayjs(stateData.calendarDateRange.start).locale(stateData.currentLocale).format('ll')
        : '';
      const endDate = stateData.calendarDateRange.end
        ? dayjs(stateData.calendarDateRange.end).locale(stateData.currentLocale).format('ll')
        : '';

      return `${startDate} - ${endDate}`;
    },
    getFormattedSingleDate(stateData) {
      return stateData.singleDate
        ? dayjs(stateData.singleDate).locale(stateData.currentLocale).format('ll')
        : '';
    },
    getHomeNumberOfDays(stateData) {
      const startDate = stateData.calendarDateRange.start || new Date();
      const endDate = stateData.calendarDateRange.end || new Date();
      const differentTime = endDate.getTime() - startDate.getTime();
      const differentDays = differentTime / (1000 * 3600 * 24);
      return differentDays;
    },
  },
});