import { apiClient } from '@/services';
import { useUserStore } from '@/stores/useUserStore';
import { useCalendarStore } from '@/stores/useCalendarStore';
import { useSaleStore } from '@/stores/useSaleStore';
import { useClaimStore } from '@/stores/useClaimStore';
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import {
  SalesGrowthPayload,
} from '@/utils/types/sale';

export const SaleService = {
  getSalesChannel(payload: any = {}) {
    const userStore = useUserStore();
    const calendarStore = useCalendarStore();
    const newPayload = {
      ...payload,
      companyName: userStore.userCompany,
      dateFrom: dayjs(calendarStore.calendarDateRange.start).format('YYYY-MM-DD'),
      dateTo: dayjs(calendarStore.calendarDateRange.end).format('YYYY-MM-DD'),
    };
    return apiClient.get('/sales/sales-channel', {
      params: newPayload,
    });
  },
  getSalesChannelHome(payload: any = {}) {
    const userStore = useUserStore();
    const calendarStore = useCalendarStore();
    const newPayload = {
      ...payload,
      companyName: userStore.userCompany,
      dateFrom: dayjs(calendarStore.calendarDateRange.start).format('YYYY-MM-DD'),
      dateTo: dayjs(calendarStore.calendarDateRange.end).format('YYYY-MM-DD'),
    };
    return apiClient.get('/sales/sales-channel', {
      params: newPayload,
    });
  },
  getSalesChannelBySingleDate(payload: any = {}) {
    const userStore = useUserStore();
    const calendarStore = useCalendarStore();
    const startDate = new Date(calendarStore.singleDate?.getTime());
    startDate.setDate(calendarStore.singleDate?.getDate() - 6);
    const newPayload = {
      ...payload,
      companyName: userStore.userCompany,
      dateFrom: dayjs(startDate).format('YYYY-MM-DD'),
      dateTo: dayjs(calendarStore.singleDate).format('YYYY-MM-DD'),
    };
    return apiClient.get('/sales/sales-channel', {
      params: newPayload,
    });
  },
  getSalesReturn(payload: any = {}) {
    const userStore = useUserStore();
    const saleStore = useSaleStore();
    // const calendarStore = useCalendarStore();
    const claimStore = useClaimStore();
    const newPayload = {
      ...payload,
      companyName: userStore.userCompany,
      // dateFrom: dayjs(calendarStore.calendarDateRange.start).format('YYYY-MM-DD'),
      // dateTo: dayjs(calendarStore.calendarDateRange.end).format('YYYY-MM-DD'),
      salesChannel: saleStore.getSelectedSaleChannel ? [saleStore.getSelectedSaleChannel] : [],
      category: claimStore.getSelectedCategories,
    };
    return apiClient.get('/sales/sales-return', {
      params: newPayload,
    });
  },
  getSalesReturnBySingleDate(payload: any = {}) {
    const userStore = useUserStore();
    const saleStore = useSaleStore();
    // const calendarStore = useCalendarStore();
    const claimStore = useClaimStore();
    // const startDate = new Date(calendarStore.singleDate?.getTime());
    // startDate.setDate(calendarStore.singleDate?.getDate() - 6);
    const newPayload = {
      ...payload,
      companyName: userStore.userCompany,
      // dateFrom: dayjs(startDate).format('YYYY-MM-DD'),
      // dateTo: dayjs(calendarStore.singleDate).format('YYYY-MM-DD'),
      salesChannel: saleStore.getSelectedSaleChannel ? [saleStore.getSelectedSaleChannel] : [],
      category: claimStore.getSelectedCategories,
    };
    return apiClient.get('/sales/sales-return', {
      params: newPayload,
    });
  },
  getYearlyGrowth(payload: any = {}) {
    const userStore = useUserStore();
    const saleStore = useSaleStore();
    const claimStore = useClaimStore();
    const newPayload = {
      ...payload,
      companyName: userStore.userCompany,
      salesChannel: saleStore.getSelectedSaleChannel ? [saleStore.getSelectedSaleChannel] : [],
      category: claimStore.getSelectedCategories,
    };
    return apiClient.get('/sales/yearly-growth', {
      params: newPayload,
    });
  },
  getSalesChannelInfo(payload: {
    companyName?: string | string[],
      type: string | string[],
      country: string | string[],
  }) {
    return apiClient.get('/sales/channel-information', {
      params: payload,
    });
  },
  getSalesOverview() {
    const saleStore = useSaleStore();
    const calendarStore = useCalendarStore();
    const { salesChannelInfo } = storeToRefs(saleStore);
    const payload = {
      dateFrom: dayjs(calendarStore.calendarDateRange.start).format('YYYY-MM-DD'),
      dateTo: dayjs(calendarStore.calendarDateRange.end).format('YYYY-MM-DD'),
      salesChannelId: salesChannelInfo.value,
    };
    return apiClient.get('/sales/overview', {
      params: payload,
    });
  },
  getSalesGrowth(period: string) {
    const saleStore = useSaleStore();
    const { salesChannelInfo } = storeToRefs(saleStore);
    const payload = {
      period,
      salesChannelId: salesChannelInfo.value,
    };
    return apiClient.get('/sales/sales-growth', {
      params: payload,
    });
  },
};