import { defineStore } from 'pinia';
import { ClaimService } from '@/services/ClaimService';
import { SettingAdjustmentService } from '@/services/SettingAdjustmentService';
import {
  ClaimOverviewData,
  ClaimOverviewResponseItem,
  ClaimCategoryOverviewData,
  ClaimCategoryResponseItem,
  ClaimItem,
  ClaimReasonDetailData,
  ClaimReasonDetailResponseItem,
  ClaimDetailData,
  ClaimDetailResponseItem,
  GroupedClaimDetails,
  ClaimCategoryItem,
  ClaimTopProductData,
  ClaimTopProductResponsePercentageItem,
} from '@/utils/types/claim';
import { SettingAdjustmentResponseItem } from '@/utils/types/settingAdjustment';
import { useCalendarStore } from './useCalendarStore';

export const useClaimStore = defineStore('claim', {
  state: () => {
    return {
      claimTotal: 0,
      claimOverview: [] as ClaimOverviewResponseItem[],
      allClaimCategoryOverview: [] as ClaimCategoryResponseItem[],
      filteredClaimCategoryOverview: [] as ClaimCategoryResponseItem[],
      claimReasonDetails: [] as ClaimReasonDetailResponseItem[],
      claimDetails: [] as ClaimDetailResponseItem[],
      claimTopProductsInfo: {
        start: null as Date | null,
        end: null as Date | null,
        category: [] as string[],
        claimTopProducts: [] as ClaimOverviewResponseItem[],
      },

      cliamSettingAdjustments: {} as SettingAdjustmentResponseItem,

      savedPageNumber: null as any,
      savedOrder: null as any,
      savedCategory: [] as string[],
      selectedCategories: [] as string[],
      selectedHomeCategories: [] as string[],
      selectedReturnTypesCategories: [] as string[],

      searchValue: '',

      grandTotalSale: 0,
      grandTotalClaim: 0,
    };
  },
  actions: {
    async getAllClaimCategoryOverview() {
      const response = await ClaimService.getClaimCategoryOverview({});
      if (response.status === 200) {
        if (response.data) {
          this.allClaimCategoryOverview = response.data;
          this.filteredClaimCategoryOverview = response.data;
        } else {
          this.allClaimCategoryOverview = [];
          this.filteredClaimCategoryOverview = [];
        }
      }
      return response;
    },

    async getFilteredClaimCategoryOverview(payload?: ClaimCategoryOverviewData) {
      const response = await ClaimService.getClaimCategoryOverview(payload);
      if (response.status === 200) {
        if (response.data) {
          this.filteredClaimCategoryOverview = response.data;
          this.grandTotalSale = response.data.grandTotalSale;
          this.grandTotalClaim = response.data.grandTotalClaim;
        } else {
          this.filteredClaimCategoryOverview = [];
          this.grandTotalSale = 0;
          this.grandTotalClaim = 0;
        }
      }
      return response;
    },

    async getFilteredClaimCategoryOverviewBySingleDate(payload?: ClaimCategoryOverviewData) {
      const response = await ClaimService.getClaimCategoryOverviewBySingleDate(payload);
      if (response.status === 200) {
        if (response.data) {
          this.filteredClaimCategoryOverview = response.data;
        } else {
          this.filteredClaimCategoryOverview = [];
        }
      }
      return response;
    },

    async getClaimReasonDetail(payload: ClaimReasonDetailData) {
      const response = await ClaimService.getClaimReasonDetail(payload);
      if (response.status === 200) {
        this.claimReasonDetails = response.data;
      }
      return response;
    },

    async getClaimDetail(payload: ClaimDetailData) {
      const response = await ClaimService.getClaimDetail(payload);
      if (response.status === 200) {
        this.claimDetails = response.data;
      }
      return response;
    },

    async getClaimTopProduct(payload: ClaimTopProductData) {
      const response = await ClaimService.getClaimTopProduct(payload);
      if (response.status === 200) {
        this.claimTopProductsInfo.claimTopProducts = response.data;
      }
      return response;
    },

    async getClaimSettingAdjustment() {
      const response = await SettingAdjustmentService.getSettingAdjustment();
      if (response.status === 200 || response.status === 201) {
        this.cliamSettingAdjustments = response.data;
      }
      return response;
    },
    updateSearchValue(val: string) {
      this.searchValue = val;
    },
    setCategories(data: string[]) {
      this.selectedCategories = data;
      sessionStorage.setItem('selectedCategories', JSON.stringify(data));
    },
    setHomeCategories(data: string[]) {
      this.selectedHomeCategories = data;
      sessionStorage.setItem('selectedHomeCategories', JSON.stringify(data));
    },
    setReturnTypesCategories(data: string[]) {
      this.selectedReturnTypesCategories = data;
      sessionStorage.setItem('selectedReturnTypesCategories', JSON.stringify(data));
    },
  },
  getters: {
    claimItems(stateData): ClaimItem[] {
      const result: ClaimItem[] = stateData.claimOverview.map((item) => {
        const claimPercentage = (item.totalClaim / item.totalSale) * 100;
        return {
          ...item,
          claimPercentage: claimPercentage,
        };
      });
      return result;
    },
    sortedByCategoryClaimCategoryOverview(stateData): ClaimCategoryResponseItem[] {
      return stateData.allClaimCategoryOverview.sort((a, b) => {
        if (a.category.toLowerCase() < b.category.toLowerCase()) {
          return -1;
        }
        if (a.category.toLowerCase() > b.category.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    },
    claimCategoryItems(stateData): ClaimCategoryItem[] {
      const result: ClaimCategoryItem[] = stateData.filteredClaimCategoryOverview.map((item) => {
        const claimPercentage = (item.totalClaim / item.totalSale) * 100;
        return {
          ...item,
          claimPercentage: claimPercentage,
        };
      });
      return result;
    },
    sortedByMostClaimPercentageClaimCategoryItems(): ClaimCategoryItem[] {
      return this.claimCategoryItems.sort((a, b) => b.claimPercentage - a.claimPercentage);
    },
    totalClaim(stateData): number {
      const getEachTotalClaims = stateData.filteredClaimCategoryOverview.map((item) => item.totalClaim);
      return getEachTotalClaims.reduce((a, b) => a + b, 0);
    },
    totalSale(stateData): number {
      const getEachTotalSales = stateData.filteredClaimCategoryOverview.map((item) => item.totalSale);
      return getEachTotalSales.reduce((a, b) => a + b, 0);
    },
    totalPercentage(): number {
      const percentage = (this.totalClaim / this.totalSale) * 100;

      return percentage || 0;
    },
    groupedClaimDetails(stateData): GroupedClaimDetails {
      return stateData.claimDetails.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.size]: (acc[cur.size as keyof ClaimDetailResponseItem] || []).concat(cur),
        };
      }, {} as GroupedClaimDetails);
    },
    colorsFromClaimDetails(stateData): string[] {
      return stateData.claimDetails.reduce((acc, cur) => {
        return acc.includes(cur.color)
          ? acc
          : acc.concat(cur.color);
      }, [] as string[]);
    },
    claimTopProductsPercentage(): ClaimTopProductResponsePercentageItem[] {
      return this.claimTopProductsInfo.claimTopProducts.map((item) => {
        return {
          ...item,
          claimPercentage: (item.totalClaim / item.totalSale) * 100,
        };
      });
    },
    getSelectedCategories(stateData): string[] {
      return stateData.selectedHomeCategories;
    },
    grandReturnRate(stateData): number {
      return (stateData.grandTotalClaim/stateData.grandTotalSale) * 100;
    },
    getClaimCategoryFilterKey(stateData): string[] {
      const filterKey = [] as string[];
      this.filteredClaimCategoryOverview.forEach((item) => {
        if (!filterKey.includes(item.category)) {
          filterKey.push(item.category);
        }
      });
      return filterKey;
    },
  },
});