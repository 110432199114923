import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { storeToRefs } from 'pinia';
import { getCookie, removeCookie } from 'typescript-cookie';

import { useUserStore } from '@/stores/useUserStore';
import { useCalendarStore } from '@/stores/useCalendarStore';
import { useClaimStore } from '@/stores/useClaimStore';
import { useProductStore } from '@/stores/useProductStore';
import { useSaleStore } from '@/stores/useSaleStore';
import dayjs from 'dayjs';
import { UserService } from '@/services/UserService';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */'@/views/home/index.vue'),
    meta: {
      layout: 'MainLayout',
      // globalSalesChannel: true,
      globalDatePicker: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */'@/views/login/index.vue'),
    meta: {
      layout: 'LoginLayout',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */'@/views/register/index.vue'),
    meta: {
      layout: 'LoginLayout',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "forgot-password" */'@/views/forgot-password/index.vue'),
    meta: {
      layout: 'LoginLayout',
    },
  },
  {
    path: '/raw',
    name: 'raw',
    component: () => import(/* webpackChunkName: "raw" */'@/views/raw/index.vue'),
    meta: {
      layout: 'MainLayout',
    },
  },
  {
    path: '/raw/customer-detail',
    name: 'raw_customer-detail',
    component: () => import(/* webpackChunkName: "raw_customer-detail" */'@/views/raw/customer-detail/index.vue'),
    meta: {
      layout: 'MainLayout',
    },
  },
  {
    path: '/raw/product-detail',
    name: 'raw_product-detail',
    component: () => import(/* webpackChunkName: "raw_product-detail" */'@/views/raw/customer-detail/product-detail/index.vue'),
    meta: {
      layout: 'MainLayout',
    },
  },
  {
    path: '/claim-overview',
    name: 'claim-overview',
    component: () => import(/* webpackChunkName: "claim-overview" */'@/views/dashboard/claim-overview/index.vue'),
    meta: {
      layout: 'MainLayout',
      globalDatePicker: true,
    },
  },
  {
    path: '/claim-overview/:productName',
    name: 'claim_productName',
    component: () => import(/* webpackChunkName: "claim_productName" */'@/views/dashboard/claim-overview/_productName/index.vue'),
    meta: {
      layout: 'MainLayout',
      globalDatePicker: true,
    },
  },
  {
    path: '/claim-overview/:productName/more-details',
    name: 'claim_productName-more-details',
    component: () => import(/* webpackChunkName: "claim_productName-more-details" */'@/views/dashboard/claim-overview/_productName/more-details/index.vue'),
    meta: {
      layout: 'MainLayout',
    },
  },
  {
    path: '/claim-overview/:productName/adjust-sizes',
    name: 'claim_productName-adjust-sizes',
    component: () => import(/* webpackChunkName: "claim_productName-adjust-sizes" */'@/views/dashboard/claim-overview/_productName/adjust-sizes/index.vue'),
    meta: {
      layout: 'MainLayout',
      globalDatePicker: true,
    },
  },
  {
    path: '/return-types',
    name: 'return-types',
    component: () => import(/* webpackChunkName: "return-types" */'@/views/dashboard/return-types/index.vue'),
    meta: {
      layout: 'MainLayout',
      globalDatePicker: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const accessToken = getCookie('user_grant_access');
  const nextRoute = to.name?.toString() || '';
  const isLoginPage = ['login', 'forgot-password'].includes(nextRoute);

  if (nextRoute === 'register') {
    next();
  } else if (!accessToken && !isLoginPage) {
    removeCookie('user_grant_access');
    removeCookie('user_data');
    window.location.href = '/login';
  } else if (!accessToken && isLoginPage) {
    next();
  } else {
    try {
      await UserService.checkToken();
      const userStore = useUserStore();
      userStore.setUserDataFromCookie();

      const calendarStore = useCalendarStore();
      const claimStore = useClaimStore();
      const productStore = useProductStore();
      const saleStore = useSaleStore();

      const calendarDateRangeStorage = sessionStorage.getItem('calendarDateRange');
      // Check if has save select date in session and has select date in pinia
      if (calendarDateRangeStorage) {
        const savedCalendarDateRange = JSON.parse(calendarDateRangeStorage);
        const startDate = new Date(savedCalendarDateRange.start);
        const endDate = new Date(savedCalendarDateRange.end);
        // If refresh page then set to pinia
        if (
          !calendarStore.calendarDateRange.start
          && !calendarStore.calendarDateRange.end
        ) {
          calendarStore.setDate({ start: startDate, end: endDate });
        }
        // If session storage and state not the same then set to pinia
        else if (
          calendarStore.calendarDateRange.start
          && calendarStore.calendarDateRange.end
          && !dayjs(calendarStore.calendarDateRange.start).isSame(dayjs(savedCalendarDateRange.start))
          && !dayjs(calendarStore.calendarDateRange.end).isSame(dayjs(savedCalendarDateRange.end))
        ) {
          calendarStore.setDate({ start: startDate, end: endDate });
        }
      } else {
        // if pinia select date store is empty
        calendarStore.setInitDate();
      }

      const selectedCategoriesStorage = sessionStorage.getItem('selectedCategories');

      if (selectedCategoriesStorage) {
        const selectedCategories = JSON.parse(selectedCategoriesStorage);
        claimStore.setCategories(selectedCategories);
      }

      const selectedHomeCategoriesStorage = sessionStorage.getItem('selectedHomeCategories');

      if (selectedHomeCategoriesStorage) {
        const selectedHomeCategories = JSON.parse(selectedHomeCategoriesStorage);
        claimStore.setHomeCategories(selectedHomeCategories);
      }

      const selectedProductDetailsColorsStorage = sessionStorage.getItem('selectedProductDetailsColors');

      if (selectedProductDetailsColorsStorage) {
        const selectedProductDetailsColors = JSON.parse(selectedProductDetailsColorsStorage);
        productStore.setProductDetailsColors(selectedProductDetailsColors);
      }

      const selectedReturnTypesCategoriesStorage = sessionStorage.getItem('selectedReturnTypesCategories');

      if (selectedReturnTypesCategoriesStorage) {
        const selectedReturnTypesCategories = JSON.parse(selectedReturnTypesCategoriesStorage);
        claimStore.setReturnTypesCategories(selectedReturnTypesCategories);
      }

      const salesChannelInfoStorage = sessionStorage.getItem('salesChannelInfo');
      if (salesChannelInfoStorage) {
        const salesChannelInfo = JSON.parse(salesChannelInfoStorage);
        saleStore.setSalesChannelInfo(salesChannelInfo);
      }

      if (isLoginPage) {
        next({ name: 'home' });
      } else {
        next();
      }
    } catch (err: any) {
      removeCookie('user_grant_access');
      removeCookie('user_data');
      window.location.href = '/login';
    }
  }
});

export default router;